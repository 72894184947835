import {create} from 'zustand'

interface IAppBannerStore {
    isShowAppBanner?: boolean
    setIsShowAppBanner: (value: boolean) => void
}

export const useAppBannerStore = create<IAppBannerStore>()((set, get) => ({
    isShowAppBanner: false,
    setIsShowAppBanner: (value: boolean) => {
        set(state => ({isShowAppBanner: value}))
    },
}))
