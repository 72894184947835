import {create} from 'zustand'

interface ISnackbarStore {
    item?: ISnackbar
    setSnackbarItem: (text: string, type?: ISnackbarAction) => void
}

export interface ISnackbar {
    text: string
    type?: ISnackbarAction
}

export interface ISnackbarAction {
    text: string
    action: () => void
}

export const useSnackbarStore = create<ISnackbarStore>()((set, get) => ({
    item: undefined,
    setSnackbarItem: (text: string, type) => {
        set(state => ({
            item: {
                text: text,
                type: {
                    text: type?.text,
                    action: type?.action,
                },
            },
        }))
    },
}))
