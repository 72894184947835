import {create} from 'zustand'
import {persist} from 'zustand/middleware'

interface IDarkMode {
    isDark: boolean
    toggleDarkMode: () => void
    setCheckDark: (status?: boolean) => void
    setViewTheme: (theme?: 'light' | 'dark') => void
    isCheckDark: boolean
}

const getUrlSearch = () => {
    if (typeof window === 'undefined') return ''
    return window?.location?.pathname
}

export const DarkModeStore = create<IDarkMode>()(
    persist(
        (set, get) => ({
            isDark: typeof window !== 'undefined' ? localStorage?.theme === 'dark' : false,
            isCheckDark: true,
            toggleDarkMode: async () => {
                set(state => ({isDark: !get()?.isDark}))
                if (get()?.isDark) {
                    localStorage.theme = 'dark'
                    document.documentElement.classList.add('dark')
                } else {
                    localStorage.theme = 'light'
                    document.documentElement.classList.remove('dark')
                }
            },
            setViewTheme: async theme => {
                if (theme === 'dark') {
                    localStorage.theme = 'dark'
                    set(() => ({isDark: true}))
                } else {
                    localStorage.theme = 'light'
                    set(() => ({isDark: false}))
                }
            },
            setCheckDark: async status => {
                set(state => ({isCheckDark: status}))
            },
        }),
        {
            name: 'use-dark-mode',
        },
    ),
)
