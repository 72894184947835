import {create} from 'zustand'
import {IAlertModal} from '@component/modal/AlertModal'

interface IAlertModalStore {
    alertModal?: IAlertModal
    setAlertModal?: (data: IAlertModal) => void
}

export const useAlertModalStore = create<IAlertModalStore>()((set, get) => ({
    alertModal: undefined,
    setAlertModal: (data: IAlertModal) => {
        set(state => ({alertModal: data}))
    },
}))
